// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1680px) and (min-width: 1280px) {
    .inner {
        width: 1170px;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////
    :root {
        --prodGap: 20px;
        --rowGap: 40px;
    }

    .static-banner {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .products-list {
        gap: 40px 20px;
    }

    .detail-columns-wrapper {
        gap: 30px;
    }

    main.aside {
        gap: var(--rowGap) 30px;
    }

    .detail-left-column {
        width: 450px;
    }

    .product-images {
        width: 450px;
    }

    .product-images .size-full {
        width: 450px;
        height: 450px;
    }
}