@media (max-width: 1680px) and (min-width: 1280px) {
  .inner {
    width: 1170px;
  }
  :root {
    --prodGap: 20px;
    --rowGap: 40px;
  }
  .static-banner {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .products-list {
    gap: 40px 20px;
  }
  .detail-columns-wrapper {
    gap: 30px;
  }
  main.aside {
    gap: var(--rowGap) 30px;
  }
  .detail-left-column {
    width: 450px;
  }
  .product-images {
    width: 450px;
  }
  .product-images .size-full {
    width: 450px;
    height: 450px;
  }
}
@media (max-width: 1279px) {
  :root {
    --rowGap: 25px;
    --fontSize: 13px;
    --lineHeight: 1.4;
  }
  .cms-subarticles-wrapper {
    columns: 2 !important;
  }
  .user-orders-list .order-header span {
    padding-left: 24px;
  }
  .user-orders-list .order-header span i {
    width: 16px;
    height: 16px;
  }
  .footer-bottom-bar {
    padding: 30px 0 100px;
  }
  #container {
    overflow: hidden;
  }
  .detail-omnibus {
    text-align: left;
  }
  main {
    padding-bottom: var(--rowGap) !important;
  }
  main.aside {
    grid-template-columns: minmax(0, 1fr);
    grid-gap: var(--rowGap) 0;
  }
  .inner {
    width: 100%;
    padding: 0 10px;
  }
  * {
    -webkit-tap-highlight-color: rgba(189, 195, 199, 0.75);
    -webkit-text-size-adjust: none;
  }
  .field-wrapper:not(.checking-reg) label {
    left: 10px;
  }
  body.privacy-box-visible {
    overflow: hidden;
  }
  body.privacy-box-visible #container {
    filter: blur(5px);
    pointer-events: none;
  }
  .privacy-box-trigger {
    bottom: 15px;
  }
  .privacy-box-trigger.right-side-cookies {
    right: 15px;
  }
  .privacy-box-trigger.left-side-cookies {
    left: 15px;
  }
  .privacy-box {
    bottom: auto;
    right: auto;
    left: 15px !important;
    right: 15px !important;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100vw;
    max-height: calc(100% - 40px);
  }
  input[type=text],
  input[type=password],
  input[type=email],
  select,
  textarea {
    font-size: 16px;
    padding: 0 14px;
  }
  textarea {
    padding: 10px 14px;
  }
  .field-wrapper:not(.checking-reg).icon input {
    padding-left: 40px;
  }
  .field-wrapper:not(.checking-reg).icon:before {
    width: 40px;
  }
  .field-wrapper:not(.checking-reg).icon label {
    left: 36px;
  }
  #facebook-box,
  .detail-loupe {
    display: none;
  }
  #detail-images .swiper-button-prev,
  #detail-images .swiper-button-next {
    height: 80px;
    margin-top: -40px;
  }
  #detail-images .swiper-button-prev {
    left: -45px;
  }
  #detail-images .swiper-button-next {
    right: -45px;
  }
  .faq-list > li > span {
    font-size: 15px;
  }
  .headline {
    font-size: 20px !important;
    margin-bottom: 10px;
  }
  #content {
    width: 100%;
    clear: both;
  }
  .sidebar {
    width: auto;
    display: contents;
  }
  .sidebar .sidebar-tabs {
    display: none;
  }
  #search-engine {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    z-index: 1001;
    padding: 80px 30px 30px;
    -webkit-overflow-scrolling: touch;
  }
  #search-engine.show-with-last-phrases .suggestion-result-products,
  #search-engine.show-with-last-phrases #suggestions .no-products-info {
    display: none !important;
  }
  #search-engine.show-with-last-phrases .suggestion-last-phrases {
    display: block !important;
  }
  #search-engine.no-products #suggestions .no-products-info {
    display: block;
  }
  #searchbutton {
    position: absolute;
    top: 81px;
    right: 33px;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #171819;
    font-size: 20px;
    background-color: white;
  }
  #searchbutton:after {
    content: '\e80d';
    font-family: icomoon;
  }
  .search-engine-close {
    position: absolute;
    height: 60px;
    display: flex;
    align-items: center;
    color: #171819;
    top: 0;
    left: 0;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;
    cursor: pointer;
    user-select: none;
    gap: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #e5e5e5;
    padding: 0 30px;
  }
  .search-engine-close:before {
    content: '\e803';
    font-size: 12px;
    font-family: icomoon;
    font-weight: normal;
  }
  .show-search-engine #search-engine {
    display: block;
  }
  #suggestions {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    width: 100%;
    filter: none;
    border-radius: 0;
    padding: 0;
    margin-top: 10px;
    overflow-y: auto;
    flex-wrap: wrap;
    opacity: 1;
    visibility: visible;
  }
  #suggestions .suggestion-result-products {
    display: none;
  }
  #suggestions.show .suggestion-result-products:not(.hidden) {
    display: block;
  }
  .suggestion-last-phrases {
    width: 100%;
    order: -1;
    margin-bottom: 20px;
    display: none;
  }
  .suggestion-last-phrases span {
    font-size: 15px;
    padding: 3px 0;
  }
  .suggestion-left-column {
    display: none;
  }
  a.suggestions-advanced-search {
    margin-top: 0;
  }
  .suggestion-result-products {
    margin-bottom: 20px;
  }
  .newsletter-bg {
    background-image: none !important;
  }
  .newsletter-input {
    width: 100%;
  }
  .footer-content {
    flex-direction: column;
    padding: 20px 0;
  }
  .footer-item {
    width: 100% !important;
  }
  #pills-container:not(:empty) {
    gap: 10px;
    margin-bottom: 10px;
  }
  body:has(.gs-filtr-container) .filter-trigger {
    display: flex;
  }
  .single-pill {
    font-size: 12px;
  }
  .remove-all-filters {
    font-size: 12px;
  }
  #filter {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 1001;
    background-color: white;
    overflow-y: auto;
    padding: 60px 10px 120px;
  }
  #filter > h3 {
    display: none;
  }
  #filter .gs-filtr-container {
    border: 0;
    padding: 0 15px;
    border-bottom: 1px solid #e5e5e5 !important;
  }
  #filter .gs-filtr-container:last-of-type {
    border-bottom: 0 !important;
  }
  #filter .gs-filtr-container.price-filtering {
    padding-bottom: 20px;
  }
  #filter .gs-filtr-container.price-filtering > strong:after {
    display: none;
  }
  #filter .gs-filtr-container.price-filtering label {
    line-height: 33px;
    font-size: var(--fontSize);
  }
  #filter .gs-filtr-container.colors ul {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }
  #filter .gs-filtr-container.colors li {
    display: block;
  }
  #filter .gs-filtr-container.colors label {
    font-size: 0;
    padding: 0;
    width: 34px;
    height: 34px;
    border: 2px solid #e5e5e5;
    outline: 4px solid white;
    outline-offset: -5px;
  }
  #filter .gs-filtr-container.colors label:before,
  #filter .gs-filtr-container.colors label:after {
    display: none;
  }
  #filter .gs-filtr-container.colors label:hover {
    border-color: #171819;
  }
  #filter .gs-filtr-container.colors input:checked + label {
    border-color: #171819;
    outline-width: 3px;
  }
  #filter .gs-filtr-container.show-list > strong:after {
    transform: translateY(-50%) scaleY(-1);
  }
  #filter .gs-filtr-container.show-list:not(.price-filtering) > div {
    display: block;
  }
  #filter .gs-filtr-container.show-list > input {
    display: block;
  }
  #filter .gs-filtr-container > strong {
    margin: 0;
    padding: 13px 0;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    color: #171819;
    font-size: 15px;
    cursor: pointer;
  }
  #filter .gs-filtr-container > strong.any-selected {
    color: #e30614;
  }
  #filter .gs-filtr-container > strong:after {
    content: '\e800';
    font-family: icomoon;
    font-weight: normal;
    font-size: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
  #filter .gs-filtr-container > strong span {
    display: block;
    color: #454545;
    font-size: 12px;
    font-weight: normal;
  }
  #filter .gs-filtr-container > strong span:empty:before {
    content: 'brak wybranych';
  }
  #filter .gs-filtr-container > input {
    font-size: 16px;
    display: none;
    margin-bottom: 15px;
    height: 40px;
  }
  #filter .gs-filtr-container.price-filtering {
    padding-left: 10px;
    padding-right: 10px;
  }
  #filter .gs-filtr-container:not(.price-filtering) > div {
    display: none;
    padding-bottom: 10px;
  }
  #filter .gs-filtr-container:not(.price-filtering):not(.colors) > div {
    padding-left: 15px;
    margin-top: -5px;
  }
  #filter .gs-filtr-container li {
    display: block !important;
  }
  #filter .gs-filtr-container button {
    display: none;
  }
  #filter .gs-filtr-container input[type=checkbox] {
    display: none;
  }
  #filter .gs-filtr-container input[type=checkbox]:checked + label:after {
    transform: scale(1);
  }
  #filter .gs-filtr-container label {
    display: block;
    cursor: pointer;
    user-select: none;
    padding: 4px 0;
    padding-left: 34px;
    position: relative;
    color: #454545;
    font-size: 14px;
    line-height: 20px;
  }
  #filter .gs-filtr-container label.hide {
    display: none !important;
  }
  #filter .gs-filtr-container label:hover {
    text-decoration: underline;
  }
  #filter .gs-filtr-container label:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: white;
    border: 1px solid #e5e5e5;
    transition: border-radius 0.1s ease-in-out;
  }
  #filter .gs-filtr-container label:after {
    content: '\e801';
    font-family: icomoon;
    position: absolute;
    top: 5px;
    left: 0;
    width: 20px;
    height: 20px;
    color: #171819;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s ease-in-out;
    transform: scale(0);
  }
  .close-filter {
    position: absolute;
    height: 60px;
    display: flex;
    align-items: center;
    color: #171819;
    background-color: #e5e5e5;
    top: 0;
    left: 0;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;
    cursor: pointer;
    user-select: none;
    gap: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 20px;
  }
  .close-filter:before {
    content: '\e803';
    font-size: 12px;
    font-family: icomoon;
    font-weight: normal;
  }
  #price-filter-amounts {
    gap: 10px;
  }
  #price-filter-amounts input {
    flex-grow: 1;
  }
  .close-filter-bottom {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-top: 1px solid #e5e5e5;
  }
  .close-filter-bottom > span {
    gap: 5px;
    font-size: 13px;
  }
  .close-filter-bottom > span[data-count]:after {
    content: attr(data-count);
  }
  .product-images-mini {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }
  .product-images-mini .swiper-wrapper {
    transform: none !important;
    width: auto;
    justify-content: center;
  }
  .product-images-mini .swiper-slide {
    width: 10px;
    height: 10px;
    background-color: #e5e5e5;
    border-radius: 50%;
    margin: 0 5px;
  }
  .product-images-mini img {
    display: none !important;
  }
  .user-custom-content p {
    margin-bottom: 10px;
  }
  .comment-input-wrapper {
    margin-bottom: 12px;
  }
  .single-saved-cart .buttons-wrapper {
    height: auto;
  }
  .single-saved-cart .buttons-wrapper a,
  .single-saved-cart .buttons-wrapper i {
    padding: 3px 10px;
    font-size: 12px;
  }
  .cart-options-buttons img {
    height: 31px;
  }
  hr {
    margin: 20px 0;
  }
  .login-form.logged .logged-menu {
    flex-wrap: wrap;
  }
  .login-form.logged .logged-menu li {
    width: 100%;
    border-right: 0;
  }
  .login-form.logged .logged-menu li:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }
  .login-form.logged .logged-menu a svg {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%) !important;
    height: 50%;
    left: 20px;
  }
  .login-form.logged .logged-header h2 {
    padding-left: 0;
    overflow: hidden;
    max-width: 100%;
  }
  .login-form.logged .logged-header h2:before {
    display: none;
  }
  .login-form.logged .logged-header ul {
    width: 100%;
  }
  .login-form.logged .logged-header ul li {
    text-align: left;
  }
  .login-form.logged .logged-header ul a {
    gap: 10px;
  }
  .login-form.logged .logged-header ul a:before {
    order: 1;
    margin: 0;
    position: relative;
    top: -1px;
  }
  .overlay-visible {
    overflow: hidden;
    height: 100dvh;
    width: 100dvw;
    position: fixed;
    top: 0;
  }
  main:not(.aside) #tree {
    display: block;
  }
  .show-menu {
    overflow: hidden;
  }
  .show-menu #tree {
    transform: translate3d(0, 0, 0);
  }
  .show-menu .tree-canvas {
    opacity: 0.6;
    pointer-events: auto;
  }
  .tree-canvas {
    position: fixed;
    background: #000000 none repeat scroll 0% 0%;
    will-change: opacity;
    transition: opacity 300ms ease 0s;
    inset: 0px;
    z-index: 1001;
    opacity: 0;
    pointer-events: none;
  }
  #tree {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    height: 100dvh;
    z-index: 1002;
    background-color: #f2f2f2;
    transform: translate3d(-100%, 0, 0);
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    overflow: hidden;
    padding-top: 60px;
  }
  #tree ul:not(.cms-rwd) {
    background-color: white;
  }
  #tree nav {
    position: relative;
    min-width: 300px;
    width: auto;
    height: 100%;
    overflow-x: hidden;
    overflow-y: visible;
    scrollbar-width: none;
  }
  #tree nav > ul {
    position: relative;
  }
  #tree nav > ul.cms-rwd a {
    font-weight: normal;
  }
  #tree nav > ul:last-child {
    padding-bottom: 30px;
  }
  #tree nav > ul:not(:empty):before {
    display: block;
    padding: 10px 20px;
  }
  #tree nav > ul:nth-of-type(2):not(:empty) {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #f2f2f2;
  }
  #tree nav > ul:nth-of-type(2):not(:empty):before {
    content: 'Menu';
  }
  #tree nav > ul:nth-of-type(2):not(:empty) a {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  #tree nav > ul:before {
    content: 'Kategorie';
    display: none;
  }
  #tree nav > ul.hide {
    left: -100%;
  }
  #tree nav > ul.hide .close-tree-header {
    display: none;
  }
  #tree nav > ul.hide + .cms-rwd {
    display: none;
  }
  #tree nav > ul ul {
    opacity: 0;
    pointer-events: none;
  }
  #tree nav > ul ul.active {
    left: 100%;
    top: 0;
    pointer-events: auto;
    opacity: 1;
  }
  #tree nav > ul ul.hide {
    left: 0;
  }
  #tree nav > ul ul.hide > .category-header {
    display: none;
  }
  #tree ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 10px;
  }
  #tree .parent > a {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }
  #tree .parent > a:after {
    content: '\e800';
    font-family: icomoon;
    font-weight: normal;
    margin-left: 20px;
    font-size: 10px;
    transform: rotate(-90deg);
  }
  #tree a:not(.tree-category-all-link) {
    padding: 15px 20px;
    color: #171819;
    display: block;
    font-weight: bold;
    font-size: 14px;
  }
  #tree a.tree-category-all-link {
    display: block;
    color: #171819;
    padding: 10px 20px;
    text-decoration: underline;
  }
  #tree a:not(.tree-category-all-link),
  #tree a.tree-category-all-link {
    /*&:hover,
            &:focus,
            &:active {
                background-color: @border-color;
            }*/
  }
  #tree li.t0 figure {
    display: none;
  }
  #tree .tree-header {
    position: fixed;
    height: 60px;
    display: flex;
    align-items: center;
    color: #171819;
    top: 0;
    left: 0;
    width: 100%;
    font-weight: bold;
    padding: 0 20px;
    text-transform: uppercase;
    font-size: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
    user-select: none;
    transform: translate3d(0, 0, 0);
  }
  #tree .tree-header:before {
    font-family: icomoon;
    font-weight: normal !important;
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  #tree .tree-header.close-tree-header:before {
    content: '\e803';
    font-size: 12px;
  }
  #tree .tree-header.category-header:before {
    content: '\e800';
    transform: rotate(90deg);
  }
  #tree button,
  #tree small {
    display: none;
  }
  .products-list,
  .products-list-tabs {
    grid-gap: var(--prodGap);
  }
  .shop-header-container {
    height: 100px;
    gap: 50px;
  }
  .desktop-account-actions {
    display: none;
  }
  .header-tools-wrapper {
    gap: 20px;
  }
  .header-contact-wrapper {
    gap: 11px;
  }
  #cart-box,
  a.log-button,
  .search-engine-trigger,
  .tree-trigger {
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    gap: 5px;
  }
  #cart-box:before,
  a.log-button:before,
  .search-engine-trigger:before,
  .tree-trigger:before {
    font-family: icomoon;
    font-size: 24px;
    color: #171819;
    line-height: 24px;
  }
  #cart-box em,
  a.log-button em,
  .search-engine-trigger em,
  .tree-trigger em {
    color: #171819;
    font-style: normal;
    font-size: 12px;
    text-transform: uppercase;
  }
  #cart-box span,
  #cart-box b {
    display: none;
  }
  #cart-box:before {
    content: '\e80e';
  }
  a.log-button:before {
    content: '\e811';
  }
  .search-engine-trigger {
    display: flex;
  }
  .search-engine-trigger:before {
    content: '\e913';
  }
  .hamburger-icon {
    width: 100%;
    height: 24px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
  }
  .hamburger-icon span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background-color: #171819;
  }
  .logo img:nth-child(2) {
    display: none;
  }
  .logo img:nth-child(1) {
    height: 60px;
  }
  .side-banner {
    display: none;
  }
  .mainmenu a {
    height: 47px;
    font-size: 15px;
  }
  .shop-header {
    margin-bottom: 20px;
  }
  .footer-item.submenu ul {
    columns: 1;
  }
  .footer-item > strong {
    margin-bottom: 10px;
  }
  .footer-item.submenu a {
    padding: 8px 0;
  }
  .footer-content {
    gap: 20px;
  }
  .sorter {
    padding-top: 0;
  }
  .sorter:before {
    display: none;
  }
  .filter-trigger {
    border: 0;
    background-color: #eeeeee;
    height: 42px;
    display: flex;
    align-items: center;
    min-width: 180px;
  }
  .filter-trigger:before {
    display: none;
  }
  .navigation-wrapper {
    gap: 10px;
    margin: 20px 0;
  }
  .category-header-wrapper {
    gap: 12px;
  }
  .headline {
    padding-bottom: 12px;
  }
  .detail-header {
    margin-bottom: 0;
  }
  .detail-columns-wrapper {
    gap: 30px;
  }
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    .header-tools-wrapper > * {
      margin-left: 10px;
    }
    .hamburger-icon {
      display: grid;
      grid-auto-rows: max-content;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 6px);
      gap: 0;
      justify-items: center;
      align-items: center;
    }
    .tree-canvas {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
@media (max-width: 1100px) {
  .check-person {
    margin-top: 20px;
  }
  .cart-final-boxes {
    display: flex;
    flex-direction: column;
  }
  .checkbox-inline {
    padding: 0 0 15px;
  }
  .checkbox-wrapper {
    padding: 10px;
  }
  .cart-checkbox-main-wrapper .has-many-checkboxes .cart-check-all {
    margin-bottom: 0;
  }
  .cart-checkbox-main-wrapper .has-many-checkboxes .cart-check-all ~ * {
    padding-left: 10px;
  }
  .fields-required-text {
    padding-top: 5px;
  }
  .oauth-signup {
    gap: 10px;
  }
  .oauth-signup > span {
    padding: 20px 0 0;
  }
  .oauth-signup > a.confirm-button {
    font-size: 0;
    padding: 0;
    width: 50px;
    min-width: 0;
  }
  .oauth-signup > a.confirm-button svg {
    margin: 0 !important;
  }
  .goshop-modal {
    padding: 20px 15px;
  }
  .comments-list {
    gap: 20px;
  }
  .comment .author:before {
    display: none;
  }
  .comment .added-date {
    margin-left: 0;
    width: 100%;
  }
  .comment .purchase-confirmed {
    width: 100%;
  }
  .field-wrapper:not(.checking-reg),
  .to-show .field-wrapper:last-of-type {
    margin-bottom: 15px !important;
  }
  #cookies-license {
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: none;
    padding: 10px 10px 10px 80px;
  }
  #cookies-license:before {
    left: 18px;
  }
  .goshop-modal.goshop-modal-form {
    width: 90vw !important;
    max-width: none !important;
  }
  .goshop-modal:not(.goshop-modal-form),
  .modal-form-content {
    padding: 30px 10px !important;
  }
  .cart-success .goshop-modal,
  .availability-modal-wrapper .goshop-modal {
    padding: 0 !important;
  }
  .payment-channels-modal .goshop-modal {
    padding: 0 !important;
  }
  .availability-modal-inner .img-wrapper {
    display: none;
  }
  .order-detailed-info .item-box-small,
  .order-detailed-info .item-box-medium {
    flex-basis: 100%;
    border-right: 0;
  }
  #detail-traits td {
    padding: 8px 15px;
  }
  .payment-channels-modal .goshop-modal .wrapper {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .header-contact-wrapper {
    display: none;
  }
  .main-banner b {
    bottom: 10px;
    left: 10px;
    padding: 5px 10px;
    font-size: 16px;
  }
  .main-greeting h1.headline + .user-custom-content {
    margin-top: 12px;
  }
  .static-banner {
    gap: 20px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .static-banner a {
    padding: 10px;
  }
  .bottom-blue-banners {
    flex-wrap: wrap;
    gap: 10px;
  }
  .bottom-blue-banners .text-wrapper b {
    font-size: 20px;
  }
  .bottom-blue-banners .text-wrapper span {
    font-size: 14px;
  }
  #newsletter {
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
  .products-list {
    gap: 30px 10px;
  }
  a.product-name {
    font-size: 12px;
  }
  .product-image {
    margin-bottom: 10px;
  }
  .product-bottom-wrapper {
    gap: 4px;
  }
  .detail-columns-wrapper {
    flex-direction: column;
    gap: 20px;
  }
  .detail-omnibus {
    margin-left: 81px;
  }
  .detail-options dt {
    font-size: 13px;
  }
}
@media (max-width: 600px) {
  .detail-share-links-wrapper span {
    width: 100%;
  }
  .order-buttons-wrapper .wrapper > * {
    flex-grow: 1;
  }
  .order-buttons-wrapper .wrapper > * .return-button {
    width: 100%;
  }
  .cart-promo-code {
    width: 100%;
  }
  .cart-header {
    gap: 10px;
  }
  .cart-header .logo {
    flex-shrink: 1;
    max-width: 40%;
  }
  .cart-header .logo img {
    height: auto;
    width: auto;
    max-height: 40px;
    max-width: 100%;
  }
  .cart-header .cart-back-link {
    white-space: nowrap;
    flex-shrink: 0;
  }
  .check-person {
    gap: 10px;
  }
  form .row-wrapper.address-wrapper,
  form .row-wrapper.city-wrapper {
    grid-template-columns: 1fr;
    gap: 0;
  }
  .stars-rating.average-rating {
    top: -2px;
  }
  .gs-tabs nav {
    flex-direction: column;
  }
  .gs-tabs nav button {
    width: 100%;
  }
  .user-custom-content iframe {
    max-width: 100%;
  }
  .privacy-box {
    padding: 10px;
    gap: 10px;
  }
  .privacy-box__header {
    gap: 10px;
  }
  .privacy-box__header img {
    height: 20px;
  }
  .privacy-box__header strong {
    font-size: 14px;
  }
  .privacy-box__actions {
    flex-wrap: wrap;
  }
  .privacy-box__actions button {
    min-height: 35px;
  }
  .privacy-box__actions button.privacy-accept-all {
    width: 100%;
    order: -1;
    flex: 0 0 auto;
    min-height: 45px;
  }
  .pagination {
    gap: 4px;
  }
  .pagination a {
    width: 30px;
    height: 30px;
  }
  .address-select-list {
    width: calc(100vw - 42px);
    overflow-x: auto;
    flex-wrap: nowrap;
    padding-top: 10px;
  }
  .cart-success .cart-popup-row-wrapper {
    flex-direction: column;
  }
  .tippy-box .tippy-content {
    padding: 10px;
  }
  .infobox {
    padding: 10px;
  }
  .infobox > * {
    width: 100%;
  }
  .infobox:before {
    font-size: 80px;
  }
  #breadcrumb {
    display: flex;
    margin-left: -10px;
    width: calc(100% + 20px);
    justify-content: flex-start;
    position: relative;
  }
  #breadcrumb .breadcrumb-end {
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 20px;
    pointer-events: none;
  }
  #breadcrumb .breadcrumb-end:nth-of-type(1) {
    left: -7px;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff, #ffffff);
  }
  #breadcrumb .breadcrumb-end:nth-of-type(2) {
    right: -7px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff, #ffffff);
  }
  #breadcrumb ul {
    flex-wrap: nowrap;
    overflow-y: auto;
    justify-content: flex-start;
    flex-direction: row-reverse;
    padding-bottom: 5px;
  }
  #breadcrumb li {
    white-space: nowrap;
  }
  #breadcrumb li:last-of-type {
    padding-right: 10px;
  }
  #breadcrumb li:first-of-type {
    padding-left: 10px;
  }
  #breadcrumb li:nth-of-type(1) {
    order: 10;
  }
  #breadcrumb li:nth-of-type(2) {
    order: 9;
  }
  #breadcrumb li:nth-of-type(3) {
    order: 8;
  }
  #breadcrumb li:nth-of-type(4) {
    order: 7;
  }
  #breadcrumb li:nth-of-type(5) {
    order: 6;
  }
  .captcha-wrapper > div {
    width: 268px !important;
    overflow: hidden;
  }
  .captcha-wrapper > div iframe {
    transform-origin: left top;
    transform: scale(0.87);
  }
  .address-select-list li:not(.no-addresses) {
    max-width: 100%;
    min-width: 75%;
  }
  #detail-images .swiper-button-prev {
    left: 0;
  }
  #detail-images .swiper-button-next {
    right: 0;
  }
  #gsGalleryContainer > a {
    width: 100%;
  }
  .navigation-wrapper {
    padding-top: 0;
    border-top: 0;
  }
  .sorter,
  .filter-trigger,
  .sorter-form,
  .sorter-button {
    min-width: 0;
    white-space: nowrap;
  }
  .filter-trigger,
  .sorter {
    width: calc((100% - 10px) / 2);
    margin-bottom: 10px;
  }
  .sorter:before,
  .filter-trigger:before {
    left: 2px;
  }
  .filter-trigger,
  .sorter-button {
    padding: 0 30px 0 10px;
  }
  .filter-trigger svg,
  .sorter-button svg {
    right: 5px;
    transform: translateY(-50%) scale(0.8);
  }
  .filter-trigger:after,
  .sorter-button:after {
    width: 30px;
  }
  .sorter-button {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .sorter-listing {
    left: auto;
    right: 0;
  }
  .goshop-modal header {
    font-size: 16px;
    padding-right: 50px;
    line-height: 1.2;
    height: 50px;
  }
  .goshop-modal header:before {
    width: 40px;
    height: 40px;
  }
  .goshop-modal header ~ .goshop-modal-close,
  .availability-modal-wrapper .goshop-modal-close {
    font-size: 14px;
    width: 50px;
    height: 50px;
  }
  .cart-success section {
    padding: 10px;
    text-align: center;
  }
  .cart-success section figure {
    width: 100%;
    height: 80px;
    margin: 0 0 10px;
  }
  .cart-success section > div {
    width: 100%;
  }
  .cart-success section > div > strong {
    font-size: 16px;
  }
  .cart-success footer {
    padding: 0 10px 10px;
  }
  .cart-success footer,
  .shop-buttons-wrapper {
    gap: 10px;
  }
  .cart-success footer > *,
  .shop-buttons-wrapper > * {
    width: 100%;
    text-align: center;
  }
  .cart-success footer > *:nth-child(2),
  .shop-buttons-wrapper > *:nth-child(2) {
    order: -1;
  }
  .availability-modal-wrapper .wrapper {
    padding: 10px;
    justify-content: center;
    gap: 10px;
  }
  .availability-modal-wrapper .wrapper > p {
    text-align: center;
  }
  .availability-modal-wrapper .wrapper > .availability-product {
    justify-content: center;
  }
  .availability-modal-wrapper .availability-product {
    gap: 10px;
    padding: 10px 0;
  }
  .availability-modal-wrapper .input-wrapper {
    flex-wrap: wrap;
  }
  .availability-modal-wrapper .input-wrapper > * {
    width: 100%;
  }
  .check-person > label {
    width: 100%;
  }
  .check-person .check-simple:first-of-type {
    margin-left: 0;
  }
  .login-form > .signup-wrapper {
    width: 100%;
    padding-right: 0;
    border-right: 0;
  }
  .login-form > .signup-wrapper:nth-child(2) {
    padding-left: 0;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #e5e5e5;
  }
  .cms-subarticles-wrapper {
    columns: 1 !important;
  }
  .user-orders-list .order-header .buttons-wrapper {
    position: static;
    float: left;
    clear: both;
    margin-top: 10px;
    width: 100%;
    flex-direction: row;
  }
  .user-orders-list .order-header .buttons-wrapper a {
    padding: 5px;
  }
  .order-detailed-info .item-box-large th:nth-child(2),
  .order-detailed-info .item-box-large td:nth-child(2) {
    display: none;
  }
  .login-form.logged .logged-header h2 {
    font-size: 18px;
  }
  .product-images {
    width: 100%;
  }
  .product-images .size-full {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
  .detail-amount {
    width: 100%;
    margin: 0 0 10px;
  }
  .thread-messages .message {
    margin-bottom: 20px;
  }
  .thread-messages .message header {
    flex-wrap: wrap;
    gap: 5px;
  }
  .thread-messages .message header .message-name {
    font-size: 14px;
  }
  .message-time {
    width: 100%;
    margin-top: -5px;
  }
  .thread-messages .message.client-response header .message-time {
    text-align: right;
  }
  .payment-channels-modal .goshop-modal .wrapper {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .pills-group:before,
  .remove-all-filters {
    font-size: 11px;
  }
  .single-pill {
    font-size: 11px;
    padding: 2px 5px;
  }
  #pills-container:not(:empty) {
    margin-bottom: 20px;
  }
  .logo {
    margin: 0;
  }
  .logo img:nth-child(1) {
    height: 48px;
  }
  .shop-header-container {
    gap: 5px;
    flex-direction: column;
    height: 110px;
    align-items: center;
    justify-content: center;
  }
  .header-actions-wrapper {
    width: 100%;
  }
  .header-tools-wrapper {
    width: 100%;
    justify-content: space-evenly;
  }
  .shop-header {
    margin-bottom: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  }
  #main-bar {
    border: 0;
  }
  .mainmenu {
    display: none;
  }
  .main-banner b {
    white-space: nowrap;
    font-size: 13px;
    text-overflow: ellipsis;
    max-width: 100%;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 20px;
    height: 20px;
    margin-top: -10px;
  }
  .swiper-button-prev {
    left: 10px;
  }
  .swiper-button-next {
    right: 10px;
  }
  .static-banner {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .newsletter-header {
    text-align: center;
  }
  .footer-bottom-bar {
    flex-direction: column;
    gap: 5px;
  }
  #pills-container:not(:empty) {
    margin-bottom: 10px;
  }
  .detail-left-column {
    width: 100%;
  }
  .detail-main-wrapper {
    gap: 20px;
  }
  #detail-traits tr,
  #detail-traits td {
    display: block;
    width: 100%;
  }
  .gs-tabs nav button {
    font-size: 14px;
  }
  #detail-tabs nav {
    margin-bottom: 20px;
  }
}
