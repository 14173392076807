// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1100px) {
    // CART
    .check-person {
        margin-top: 20px;
    }

    .cart-final-boxes {
        display: flex;
        flex-direction: column;
    }

    .checkbox-inline {
        padding: 0 0 15px;
    }

    .checkbox-wrapper {
        padding: 10px;
    }

    .cart-checkbox-main-wrapper .has-many-checkboxes .cart-check-all {
        margin-bottom: 0;
    }

    .cart-checkbox-main-wrapper .has-many-checkboxes .cart-check-all ~ * {
        padding-left: 10px;
    }

    .fields-required-text {
        padding-top: 5px;
    }

    .oauth-signup {
        gap: 10px;
    }

    .oauth-signup > span {
        padding: 20px 0 0;
    }

    .oauth-signup > a.confirm-button {
        font-size: 0;
        padding: 0;
        width: 50px;
        min-width: 0;

        svg {
            margin: 0 !important;
        }
    }

    .goshop-modal {
        padding: 20px 15px;
    }

    .comments-list {
        gap: 20px;
    }

    .comment .author:before {
        display: none;
    }

    .comment .added-date {
        margin-left: 0;
        width: 100%;
    }

    .comment .purchase-confirmed {
        width: 100%;
    }

    .field-wrapper:not(.checking-reg),
    .to-show .field-wrapper:last-of-type {
        margin-bottom: 15px !important;
    }

    // OTHER
    #cookies-license {
        bottom: 0;
        right: 0;
        width: 100%;
        max-width: none;
        padding: 10px 10px 10px 80px;

        &:before {
            left: 18px;
        }
    }

    .goshop-modal.goshop-modal-form {
        width: 90vw !important;
        max-width: none !important;
    }

    .goshop-modal:not(.goshop-modal-form),
    .modal-form-content {
        padding: 30px 10px !important;
    }

    .cart-success .goshop-modal, .availability-modal-wrapper .goshop-modal {
        padding: 0 !important;
    }

    .payment-channels-modal .goshop-modal {
        padding: 0 !important;
    }

    .availability-modal-inner {

        .img-wrapper {
            display: none;
        }
    }

    .order-detailed-info .item-box-small,
    .order-detailed-info .item-box-medium {
        flex-basis: 100%;
        border-right: 0;
    }

    #detail-traits td {
        padding: 8px 15px;
    }

    .payment-channels-modal .goshop-modal .wrapper {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////
    .header-contact-wrapper {
        display: none;
    }

    .main-banner b {
        bottom: 10px;
        left: 10px;
        padding: 5px 10px;
        font-size: 16px;
    }

    .main-greeting h1.headline + .user-custom-content {
        margin-top: 12px;
    }

    .static-banner {
        gap: 20px;
        grid-template-columns: repeat(4, minmax(0, 1fr));

        a {
            padding: 10px;
        }
    }
    
    .bottom-blue-banners {
        flex-wrap: wrap;
        gap: 10px;
    }

    .bottom-blue-banners .text-wrapper b {
        font-size: 20px;
    }

    .bottom-blue-banners .text-wrapper span {
        font-size: 14px;
    }

    #newsletter {
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }

    .products-list {
        gap: 30px 10px;
    }

    a.product-name {
        font-size: 12px;
    }

    .product-image {
        margin-bottom: 10px;
    }

    .product-bottom-wrapper {
        gap: 4px;
    }

    .detail-columns-wrapper {
        flex-direction: column;
        gap: 20px;
    }

    .detail-omnibus {
        margin-left: 81px;
    }

    .detail-options dt {
        font-size: 13px;
    }
}